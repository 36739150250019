@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  img {
    display: initial;
  }
}

html {
  overflow: hidden;
}

.react-tel-input .form-control {
  width: calc(100% - 4px) !important;
  border-radius: 0.25rem !important;
  border-width: 1px !important;
  padding-block: 1rem !important;
  @apply border-gray-200 shadow-sm dark:border-gray-800 dark:bg-transparent !important;
}

.react-tel-input .form-control:focus {
  outline: 1px solid var(--tw-blue) !important;
}

.react-tel-input .flag-dropdown {
  @apply border-gray-300 bg-white shadow-sm dark:border-gray-800 dark:bg-gray-900 !important;
}

.selected-flag,
.country-list {
  @apply border-gray-300 bg-white shadow-sm dark:border-gray-800 dark:bg-gray-900 !important;
}

.country {
  @apply dark:hover:bg-gray-900 !important;
}

.highlight {
  @apply dark:bg-gray-700 !important;
}

*::-webkit-scrollbar {
  width: 15px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  @apply bg-gray-200 hover:bg-gray-300 dark:bg-gray-700 dark:hover:bg-gray-600;
  border-radius: 20px;
  background-clip: padding-box;
  border: 5px solid rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar-thumb:hover {
  background-clip: padding-box;
  border: 5px solid rgba(0, 0, 0, 0);
}

/* table */

table,
th,
td,
tr {
  white-space: nowrap;
}

td,
th {
  @apply py-2 px-3;
}

th {
  @apply font-semibold;
}

textarea {
  resize: none !important;
}

/* Hidding scroll bar if class no-scrollbar is applied */
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.dialog-overlay {
  filter: blur(3px);
  pointer-events: none;
  user-select: none;
}

.dialog-overlay {
  position: relative;
  background: rgba(0, 0, 0, 0.01);
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog-box-sm {
  width: 200px;
  height: 150px;
  position: absolute;
  top: calc(50% - 75px);
  left: calc(50% - 100px);
  background: white;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 1001;
}
